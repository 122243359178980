import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import soutien from '../img/soutien-icone-rond-blanc.png';
import sensibilisation from '../img/sensibilisation-icone-rond-blanc.png';
import recherche from '../img/recherche-icone-rond-blanc.png';
import { Layout, SEO, Blurb, PreviewCompatibleImage } from "../components";
import { HomeHeader, Section, ImageContainer, CardContainer, CardWithButton } from "../ui";
import { Column, Container, Row, Wrapper } from "../ui/LayoutComponents";
import { SectionTitle, Span, Text } from "../ui/Texts";
import { ContainedButton, OutlinedButton } from "../ui/Buttons";
import { colors } from "../ui/variables";

import bgImage from "../img/header-images/banniere-site.png";

export const IndexPageTemplate = ({
  presentation,
  blurbs,
  lhfDisease,
  research,
  actions,
  actualites,
  donMoelleOsseuse,
  temoignages,
}) => {
  const { blurb1, blurb2, blurb3 } = blurbs;

  return (
    <> 
      <SEO />
      <HomeHeader backgroundImage={bgImage}/>
      <Section blueLight paddingLg="0">
        <Container large>
          <Wrapper padding="0px 200px 100px" paddingLg="0 0 40px">
            <Text center weight="500" height="22px" size="20px" mobileSize="15px">
              LHF Espoir est une association de patients qui a pour objectif de lutter contre la maladie de<br/>
              <Span bold color={colors.vermillon} size="20px" mobileSize="15px">Lymphohistiocytose Hémophagocytaire Familiale (LHF).</Span>
            </Text>
          </Wrapper>
        </Container>
      </Section>
      <Section blue>
        <Container large>
          <Wrapper paddingLg="0">
          <Row mobile flex={1} justifyContent="space-between" alignItems="flex-start" margin="50px 0 0 0">
            <Blurb color={colors.darkBlue} icon={soutien} title={blurb1.title} text={blurb1.text} />
            <Blurb color={colors.vermillon} icon={sensibilisation} title={blurb2.title} text={blurb2.text} />
            <Blurb color={colors.secondaryBlue} icon={recherche} title={blurb3.title} text={blurb3.text} last />
          </Row>
          </Wrapper>
        </Container>
      </Section>
      <Section darkBlue>
        <Container large>
          <Wrapper paddingLg="0">
            <SectionTitle color={colors.secondaryBlue}>{lhfDisease.title}</SectionTitle>
            <Text center color={colors.white}>La Lymphohistiocytose Familiale (LHF) est une <Span bold color={colors.secondaryBlue}>maladie génétique rare de déficit immunitaire.</Span> Elle a été découverte pour la première fois dans les années 1950. C’est seulement au cours des vingt dernières années que des avancées importantes ont été effectuées.</Text><br/>
            <Text center color={colors.white}>La maladie de LHF continue d’évoluer avec l’identification de nouveaux gènes responsables et l’apparition de nouveaux symptômes cliniques. Son diagnostic et son traitement sont encore au stade de la recherche et il existe encore aujourd’hui un nombre important de malades non identifiés.</Text><br/>
            <Text center color={colors.white}>
              <Span bold color={colors.vermillon}>Il est donc urgent d’agir et vite !</Span>
            </Text><br/>
          </Wrapper>
        </Container>
      </Section>
      <Section blueLight>
        <Container large>
          <Wrapper paddingLg="0">
            <Row mobile flex={1}>
              <ImageContainer maxWidth="700px" imgLeft margin="3rem">
                <PreviewCompatibleImage
                  imageInfo={research.image}
                  style={{ borderRadius: "3px" }}
                />
              </ImageContainer>
              <Column>
                <SectionTitle>{research.title}</SectionTitle>
                <Span center bold>
                  {research.sub1}
                </Span>
                <Span center bold color={colors.secondaryBlue} size="24px">
                  {research.sub2}
                </Span>
                <Span center bold size="17px">
                  {research.sub3}
                </Span>
                <Span center bold size="17px" style={{ marginBottom: "22px" }}>
                  {research.sub4}
                </Span>
                <Text center>{research.text}</Text>
                <ContainedButton margin="2.5rem 0 0 0" to={research.url}>En savoir plus</ContainedButton>
              </Column>
            </Row>
          </Wrapper>
        </Container>
      </Section>
      <Section>
        <Container large>
          <Wrapper paddingLg="0">  
            <Column>
              <SectionTitle marginB="2.5rem">Nos dernières actions</SectionTitle>
              <CardContainer two>
                {actions.map(({ node: post }) => {
                  const { id, frontmatter, fields, excerpt } = post;
                  const image = frontmatter.thumbnail ? frontmatter.thumbnail : frontmatter.image;
                  return (
                    <CardWithButton
                    key={id}
                      actionStyle
                      title={frontmatter.title}
                      date={frontmatter.date}
                      text={excerpt}
                      link={fields.slug}>
                      <PreviewCompatibleImage
                        imageInfo={image}
                        style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                      />
                    </CardWithButton>
                  );
                })}
              </CardContainer>
              <OutlinedButton to="/actions">Voir toutes nos actions</OutlinedButton>
            </Column>
          </Wrapper>
        </Container>
      </Section>
      <Section grey>
        <Container large>
          <Wrapper paddingLg="0">
            <Column>
              <SectionTitle marginB="2.5rem">Actualités</SectionTitle>
              <CardContainer two>
                {actualites.map(({ node: post }) => {
                  const { id, frontmatter, fields, excerpt } = post;
                  const image = frontmatter.thumbnail ? frontmatter.thumbnail : frontmatter.image;
                  return (
                    <CardWithButton
                      key={id}
                      actionStyle
                      title={frontmatter.title}
                      date={frontmatter.date}
                      text={excerpt}
                      link={fields.slug}>
                      <PreviewCompatibleImage
                        imageInfo={image}
                        style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                        />
                    </CardWithButton>
                  );
                })}
              </CardContainer>
              <OutlinedButton to="/actualites">Voir toutes les actualités</OutlinedButton>
            </Column>
          </Wrapper>
        </Container>
      </Section>
      <Section>
        <Container large>
          <Wrapper paddingLg="0">
            <Column>
              <SectionTitle marginB="2.5rem">Nos campagnes sur le don de moelle osseuse</SectionTitle>
              <CardContainer two>
                {donMoelleOsseuse.map(({ node: post }) => {
                  const { id, frontmatter, fields } = post;
                  return (
                    <CardWithButton
                      key={id}
                      actionStyle
                      title={frontmatter.title}
                      date={frontmatter.date}
                      text={frontmatter.description}
                      link={fields.slug}>
                      <PreviewCompatibleImage
                        imageInfo={frontmatter.image}
                        style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                      />
                    </CardWithButton>
                  );
                })}
              </CardContainer>
              <OutlinedButton to="/don-moelle-osseuse">Voir toutes nos campagnes</OutlinedButton>
            </Column>
          </Wrapper>
        </Container>
      </Section>
      <Section grey>
        <Container large>
          <Wrapper paddingLg="0">
            <Column>
              <SectionTitle marginB="2.5rem">Témoignages sur la LHF</SectionTitle>
              <CardContainer two>
                {temoignages.map(({ node: post }) => {
                  const { id, frontmatter, fields } = post;
                  return (
                    <CardWithButton
                      key={id}
                      actionStyle
                      title={frontmatter.title}
                      text={frontmatter.description}
                      link={fields.slug}
                      buttonText="Lire le témoignage >">
                      <PreviewCompatibleImage
                        imageInfo={frontmatter.image}
                        style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                      />
                    </CardWithButton>
                  );
                })}
              </CardContainer>
              <OutlinedButton to="/temoignages-lhf">Voir tous les témoignages sur la LHF</OutlinedButton>
            </Column>
          </Wrapper>
        </Container>
      </Section>
    </>
  );
};

IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  presentation: PropTypes.object,
  blurbs: PropTypes.object,
  lhfDisease: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  research: PropTypes.object,
  actions: PropTypes.array,
  actualites: PropTypes.array,
  donMoelleOsseuse: PropTypes.array,
  temoignages: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: actions } = data.actions;
  const { edges: actualites } = data.actualites;
  const { edges: donMoelleOsseuse } = data.donMoelleOsseuse;
  const { edges: temoignages } = data.temoignages;

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        presentation={frontmatter.presentation}
        blurbs={frontmatter.blurbs}
        lhfDisease={frontmatter.lhfDisease}
        research={frontmatter.research}
        actions={actions}
        actualites={actualites}
        donMoelleOsseuse={donMoelleOsseuse}
        temoignages={temoignages}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    actions: allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "action-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 230)
          id
          fields {
            slug
          }
          frontmatter {
            title
            fullDate: date
            date(formatString: "MMMM YYYY", locale: "fr")
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    actualites: allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "actualite-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 230)
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "D MMM YYYY", locale: "fr")
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    donMoelleOsseuse: allMarkdownRemark(
      limit: 2
      filter: { frontmatter: { templateKey: { eq: "don-moelle-osseuse-post" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    temoignages: allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { slug: { regex: "/temoignages-lhf/", ne: "/temoignages-lhf/" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heading
        subheading
        presentation {
          title
          text
        }
        blurbs {
          blurb1 {
            title
            text
          }
          blurb2 {
            title
            text
          }
          blurb3 {
            title
            text
          }
        }
        lhfDisease {
          title
          text
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        research {
          title
          sub1
          sub2
          sub3
          sub4
          text
          url
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
